

































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class NavBar extends Vue {
  // Data
  color = "primary";

  routes = [
    {
      path: "/",
      name: "Create Task",
      requiresSuperuser: true
    },
    {
      path: "/create-photocollection",
      name: "Create PhotoCollection",
      requiresSuperuser: true
    },
    {
      path: "/categories",
      name: "Add Category",
      requiresSuperuser: true
    },
    {
      path: "/questions",
      name: "Questions",
      requiresSuperuser: true
    },
    {
      path: "/tasks",
      name: "Tasks",
      requiresSuperuser: false
    },
    {
      path: "/photo-collections",
      name: "Photo Collections",
      requiresSuperuser: false
    },
    {
      path: "/export",
      name: "Export",
      requiresSuperuser: false
    }
  ];

  loginRoute = {
    path: "/login",
    name: "Login"
  };

  logoutRoute = {
    path: "/logout",
    name: "Logout"
  };

  // Computed
  get accessToken() {
    return this.$store.state.accessToken;
  }

  get isSuperuser() {
    return this.$store.getters.isSuperuser;
  }

  get filteredRoutes() {
    return this.routes.filter(route => {
      return this.isSuperuser || !route.requiresSuperuser;
    });
  }
}
